const logotext = 'victorjimenez';
const meta = {
  title: 'Victor Jimenez',
  description:
    'I’m Victor Jimenez, a Temple student and self-taught developer currently based in Philadelphia.',
};

const introdata = {
  title: 'I’m Victor Jimenez',
  animated: {
    first: 'I ❤️ entrepeneurship',
    second: 'I code cool websites',
    third: 'I develop mobile apps',
  },
  description:
    'I’m passionate about leveraging technology to create innovative solutions that make a positive impact. With a background in computer science, physics and full-stack development, I enjoy tackling complex problems and bringing ideas to life through software development.',
  your_img_url: 'https://i.imgur.com/Grvphqd.png',
};

const dataabout = {
  title: 'A Bit About Myself',
  aboutme:
    'I have a keen interest in exploring the intersections of technology, entrepreneurship, and innovation. With a diverse skill set and a drive for continuous learning, I strive to create meaningful solutions that address real-world challenges and enhance user experiences.',
};
const worktimeline = [
  {
    jobtitle: 'Freelance Web Developer',
    where: 'Fiverr',
    date: '2021-Present',
  },
  {
    jobtitle: 'Teller',
    where: 'Wells Fargo',
    date: '2022-Present',
  },
  {
    jobtitle: 'Team Leader',
    where: 'Chick-fil-A',
    date: '2019-2021',
  },
  {
    jobtitle: 'Team Leader',
    where: 'Chick-fil-A',
    date: '2019-2021',
  },
];

const skills = [
  {
    name: 'Javascript',
    value: 90,
  },
  {
    name: 'Python',
    value: 85,
  },
  {
    name: 'React Native',
    value: 80,
  },
  {
    name: 'React',
    value: 70,
  },
  {
    name: 'C',
    value: 60,
  },
];

const services = [
  {
    title: 'Web Development',
    description:
      'I specialize in developing responsive and user-friendly websites using cutting-edge technologies and industry best practices.',
  },
  {
    title: 'Mobile Apps',
    description:
      'I design and develop mobile applications for both iOS and Android platforms, focusing on intuitive user interfaces and seamless experiences.',
  },
  {
    title: 'AI Counseling',
    description:
      'I provide consultation and implementation services for integrating AI solutions into various business processes, optimizing efficiency and performance.',
  },
];

const dataportfolio = [
  {
    img: 'https://i.ibb.co/MPPV6kP/d3aab06aefd0a7c1b2423122b32913a4.png',
    description:
      'Study Owl is a student planner app that easily integrates with LMS systems like Canvas, streamlining academic tasks, task management, and collaboration while providing a user-friendly interface. 🦉📚',
    link: 'https://github.com/bictorg/bictorg.git',
  },
  {
    img: 'https://i.ibb.co/VSjZmzS/image.png',
    description:
      '(Name TBD) is an innovative AI agency that empowers businesses by automating workflows using cutting-edge AI technology, resulting in faster customer intake and heightened satisfaction. 🚀🤖📈',
    link: 'https://github.com/bictorg/bictorg.git',
  },
  //   {
  //     img: 'https://picsum.photos/400/?grayscale',
  //     description:
  //       'The wisdom of life consists in the elimination of non-essentials.',
  //     link: '#',
  //   },
  //   {
  //     img: 'https://picsum.photos/400/600/?grayscale',
  //     description:
  //       'The wisdom of life consists in the elimination of non-essentials.',
  //     link: '#',
  //   },
  //   {
  //     img: 'https://picsum.photos/400/300/?grayscale',
  //     description:
  //       'The wisdom of life consists in the elimination of non-essentials.',
  //     link: '#',
  //   },
  //   {
  //     img: 'https://picsum.photos/400/700/?grayscale',
  //     description:
  //       'The wisdom of life consists in the elimination of non-essentials.',
  //     link: '#',
  //   },

  //   {
  //     img: 'https://picsum.photos/400/600/?grayscale',
  //     description:
  //       'The wisdom of life consists in the elimination of non-essentials.',
  //     link: '#',
  //   },
  //   {
  //     img: 'https://picsum.photos/400/300/?grayscale',
  //     description:
  //       'The wisdom of life consists in the elimination of non-essentials.',
  //     link: '#',
  //   },
  //   {
  //     img: 'https://picsum.photos/400/?grayscale',
  //     description:
  //       'The wisdom of life consists in the elimination of non-essentials.',
  //     link: '#',
  //   },
  //   {
  //     img: 'https://picsum.photos/400/550/?grayscale',
  //     description:
  //       'The wisdom of life consists in the elimination of non-essentials.',
  //     link: '#',
  //   },
  //   {
  //     img: 'https://picsum.photos/400/?grayscale',
  //     description:
  //       'The wisdom of life consists in the elimination of non-essentials.',
  //     link: '#',
  //   },
  //   {
  //     img: 'https://picsum.photos/400/700/?grayscale',
  //     description:
  //       'The wisdom of life consists in the elimination of non-essentials.',
  //     link: '#',
  //   },
];

const contactConfig = {
  YOUR_EMAIL: 'vj.jimeneznunez@gmail.com',
  YOUR_FONE: '267 356 1271',
  description:
    'Feel free to reach out to me via email or phone for inquiries, collaborations, or just to say hello!',
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: 'business-gmail',
  YOUR_TEMPLATE_ID: 'contact-reply',
  YOUR_USER_ID: 'WZp4qoHLUdCZbeaxj',
};

const socialprofils = {
  github: 'https://github.com/bictorg',
  // facebook: 'https://facebook.com',
  linkedin: 'https://www.linkedin.com/in/victor-jimenez-nunez-7b097219a/',
  handshake: 'https://app.joinhandshake.com/stu/users/24797325',
  // twitter: 'https://twitter.com',
};
export {
  meta,
  dataabout,
  dataportfolio,
  worktimeline,
  skills,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
};
